import React, { useEffect, useState } from 'react';
import isbot from 'isbot';
import bemCn from 'bem-cn';
import loadable from '@loadable/component';
import { SIGN_UP_SRC } from '../../consts/profile.const';
import { RouteFormatter } from '../../routes';
import { USER_TEST_GROUP } from '../../consts/profile.const';
import { COOKIE_PARAM_NAME } from '../../consts/cookies.consts';
import { getTestWithProbSSR } from '../../helpers/ssr';
import { toasterDanger } from '../sniff-toast';
import SvgCross2 from 'src/assets/svg/SvgCross2.svg';
import './style.scss';

const CookieService = require('../../services/cookie.service');
const useGetProbabilityTest = require('../../services/hooks/useGetProbabilityTest');
const useMutateViewOfferModalV3 = require('../../services/hooks/useMutateViewOfferModalV3');

const RenderSignUpEmail = loadable(() => import('../../pages/host-landing/render-sign-up-email'));

const b = bemCn('signup-popup-seo');
const bl = bemCn('host-landing');

const COUPON_CODE = 'HAPPYPAWS';

const checkUrl = (url) => {
    const regex = /\d$/;
    return !regex.test(url);
};

const SignupPopupSeo = () => {
    const { getProbTest } = useGetProbabilityTest.default();
    const { mutateViewOfferModalV3 } = useMutateViewOfferModalV3.default();
    const [canShow, setCanShow] = useState(false);
    const [finished, setFinished] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [couponOpen, setCouponOpen] = useState(false);
    const [pathname, setPathname] = useState('');
    const incPages = pathname == '/' || (pathname.startsWith('/listings') && checkUrl(pathname));
    const isBotNavTest = CookieService.default.get(COOKIE_PARAM_NAME.FRONT_BOT_NAV) == 'NEW';

    const handleClose = () => {
        CookieService.default.set(COOKIE_PARAM_NAME.OFFER_MODAL_V3_SHOWN, 'true', { expires: 90 });
        setCanShow(false);
    };

    const toggleCouponOpen = () => {
        setCouponOpen(false);

        // redirect
        const thisPage = window.location.pathname;

        if (thisPage == RouteFormatter.listings({})) {
            window.location.reload();
        } else {
            window.location = RouteFormatter.listings({});
        }
    };

    const copyDisc = () => {
        navigator.clipboard.writeText(COUPON_CODE);
        setIsCopied(true);
    };

    useEffect(() => {
        const init = async () => {
            const myPath = window.location.pathname;
            const isShown = CookieService.default.get(COOKIE_PARAM_NAME.OFFER_MODAL_V3_SHOWN);
            const isSamePath = myPath == isShown;

            if (typeof isShown === 'string' && !isSamePath) {
                CookieService.default.set(COOKIE_PARAM_NAME.OFFER_MODAL_V3_SHOWN, 'true', { expires: 90 });
                return;
            }

            const isSeoPopupTest = await getTestWithProbSSR({
                getProbTest,
                cookie: COOKIE_PARAM_NAME.OFFER_MODAL_V3,
                userTestGroup: USER_TEST_GROUP.OFFER_MODAL_V3,
            });

            if (isSeoPopupTest !== null) {
                mutateViewOfferModalV3({
                    variables: { testGroup: isSeoPopupTest, sessionUuid: CookieService.default.get(COOKIE_PARAM_NAME.SESSION_UUID) },
                });
            }

            if (isSeoPopupTest === false) {
                CookieService.default.set(COOKIE_PARAM_NAME.OFFER_MODAL_V3_SHOWN, myPath, { expires: 90 });
            }

            if (isSeoPopupTest) {
                setCanShow(true);
                CookieService.default.set(COOKIE_PARAM_NAME.OFFER_MODAL_V3_SHOWN, myPath, { expires: 90 });
            }
        };

        const isBotCrawl = isbot(navigator.userAgent);
        !isBotCrawl && init();
        setPathname(window.location.pathname);

        return () => {
            setCanShow(false);
        };
    }, []);

    if (couponOpen) {
        return (
            <div className={b('seopopup-after-reg')}>
                <div className={b('seopopup-after-reg_bg')} onClick={toggleCouponOpen} />
                <div className={b('seopopup-after-reg_content')}>
                    <p className={b('seopopup-after-reg_close')} onClick={toggleCouponOpen}>
                        Close
                        <SvgCross2 />
                    </p>
                    <h2 className="snif-m2 snif-semibold ">Thank You for Signing Up! 🎉</h2>
                    <p className="snif-p mt-1">Enter this code at checkout to get $5 off your first booking.</p>
                    <div className={b('seopopup-after-reg_coupon')}>{COUPON_CODE}</div>
                    <p className="snif-s1 text-deep mt-2">
                        {isCopied ? (
                            <span>Copied!</span>
                        ) : (
                            <span className="c-pointer" onClick={copyDisc}>
                                Copy discount code
                            </span>
                        )}
                    </p>
                </div>
            </div>
        );
    }

    if (canShow) {
        return (
            <div
                className={b
                    .mix(finished ? 'd-none' : '')
                    .mix(isFocused ? 'focused' : '')
                    .mix(incPages && isBotNavTest ? 'bot-nav-test' : '')}
            >
                <div className={b('title')}>
                    <p>Get $5 off your first booking!</p>
                    <div onClick={handleClose}>
                        <SvgCross2 />
                    </div>
                </div>
                <RenderSignUpEmail
                    src={SIGN_UP_SRC.SEOPOPUP_WEB}
                    signUp
                    seoPopup
                    {...{
                        b: bl,
                        isFocused,
                        setIsFocused,
                        toasterDanger,
                        useStateSSR: useState,
                        setFinished,
                        setCouponOpen,
                    }}
                />
            </div>
        );
    }

    return '';
};

export default SignupPopupSeo;
